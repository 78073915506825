<script setup lang="ts">

import GameLayout from "@/Layouts/GameLayout.vue";
import {useQRCode} from "@vueuse/integrations/useQRCode";

const props = defineProps<{
    link: string
}>()

const qrcode = useQRCode(props.link)

</script>

<template>
    <game-layout>
        <div class="flex w-full h-full justify-center items-center user-select-none">
            <div class="w-3/5 max-w-[250px]">
                <img :src="qrcode" alt="QR Code" class="w-full h-auto"/>
            </div>
        </div>
    </game-layout>
</template>
